import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";

import { OverlayContext, OverlayType } from "@components/legacy";
import HeroSectionMain from "@components/organisms/Home/homepage-desktop/HeroSectionMain";
import HeroSectionTitle from "@components/organisms/Home/homepage-desktop/HeroSectionTitle";
import Quiz from "@components/organisms/Quiz/ShortQuiz";
import useRedirectToDashboard from "@hooks/useRedirectToDashboard";
import { breakpoints } from "@styles/constants";

import HeroDesktopLeft from "images/hero/hero_desktop_left_4.jpg";
import HeroDesktopRight from "images/hero/hero_desktop_right_4.jpg";
import HeroDesktopLeftWide from "images/hero/hero_desktop_wide_left_4.jpg";
import HeroDesktopRightWide from "images/hero/hero_desktop_wide_right_4.jpg";

const handleWidthBasedOnHover = (
  position: string,
  hoveredSection: string
): string => {
  if (hoveredSection === "none") {
    return "50%";
  }

  if (position === hoveredSection) {
    return "53%";
  }

  return "47%";
};

const HeroSectionDesktop: React.FC = () => {
  const { redirectToDashboard } = useRedirectToDashboard();

  const [hoveredSection, setHoveredSection] = useState<
    "none" | "left" | "right"
  >("none");
  const overlay = React.useContext(OverlayContext);
  const [isDesktopWide] = useMediaQuery(`(min-width: ${breakpoints.xxl})`);

  return (
    <Flex w="100%" h="800px" position="relative" display="flex">
      <HeroSectionMain
        setHoveredSection={setHoveredSection}
        position="left"
        hoveredSection={hoveredSection}
        handleWidthBasedOnHover={handleWidthBasedOnHover}
        ctaTitle="SHARE THE DRESS"
        ctaButton="Become a lender &nbsp;&nbsp;⟶"
        onClickCtaAction={redirectToDashboard}
        imageSrc={isDesktopWide ? HeroDesktopLeftWide : HeroDesktopLeft}
        alignItems="end"
      />
      <HeroSectionMain
        onClickCtaAction={() =>
          overlay.toggle(OverlayType.modal, undefined, {
            content: <Quiz />,
          })
        }
        setHoveredSection={setHoveredSection}
        position="right"
        hoveredSection={hoveredSection}
        handleWidthBasedOnHover={handleWidthBasedOnHover}
        ctaTitle="WEAR THE DRESS"
        ctaButton="Rent the collection&nbsp;&nbsp;⟶"
        imageSrc={isDesktopWide ? HeroDesktopRightWide : HeroDesktopRight}
        alignItems="end"
        altTitleColor
      />
      <Box
        backgroundColor="transparent"
        position="absolute"
        width="100%"
        height="70%"
        left="50%"
        transform="translate(-50%, 0)"
        top="30%"
        display="flex"
        zIndex={100}
      >
        <Box
          width={handleWidthBasedOnHover("left", hoveredSection)}
          height="full"
          overflow="hidden"
          position="relative"
          onMouseEnter={() => setHoveredSection("left")}
        />
        <Box
          width={handleWidthBasedOnHover("right", hoveredSection)}
          height="full"
          overflow="hidden"
          position="relative"
          onMouseEnter={() => setHoveredSection("right")}
        />
      </Box>
      <HeroSectionTitle />
    </Flex>
  );
};

export default HeroSectionDesktop;
